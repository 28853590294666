/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Menu() {
    return (
        <Fragment>
            <header className="theme-main-menu sticky-menu theme-menu-two">
                <div className="inner-content">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo"><Link to="/" className="nav-link"><img src="assets/images/logo/logo-due-idee-purp.png" alt="" width="127" /></Link></div>
                        <nav className="navbar navbar-expand-lg">
                            <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="d-block d-lg-none"><div className="logo"><a href="index.html"><img src="assets/images/logo/logo-due-idee-purp.png" alt="" width="127" /></a></div></li>
                                    <li className="nav-item active dropdown mega-dropdown">
                                        {/* <Link to="/" className="nav-link">Inicio</Link> */}
                                    </li>
                                </ul>
                                <div className="mobile-content d-block d-lg-none">
                                    <ul className="navbar-nav">
                                        <li className="nav-item active dropdown mega-dropdown">
                                            <Link to="/" className="nav-link">Inicio</Link>
                                            <Link to="/contact" className="nav-link">Contáctanos</Link>
                                        </li>
                                    </ul>
                                    <div className="address-block">
                                        <h4 className="title">Dirección</h4>
                                        <p>Av. Manquehue Sur 31, Local 329<br />Las Condes, Santiago, Chile</p>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="right-widget d-flex align-items-center">
                            <a href="/contact" className="tran3s contact-btn d-none d-sm-block">
                                <span>Contáctanos</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default Menu;