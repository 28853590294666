/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import { Buffer } from 'buffer';
import axios from "axios";
import Reaptcha from "reaptcha";
import { backendApiUrl, templateID, contactInformation } from "../constants/defaultValues";

function Contact(){
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [contactSended, setContactSended] = useState(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const getOautToken = async () => {
        const oauth = await fetch(
            backendApiUrl+'/oauth', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + Buffer.from('front.web.dueidee:front.web.dueidee').toString('base64'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'grant_type' : 'client_credentials' }),
              }
        ).then((response) => response.json());
        return oauth.access_token;
    };    

    const onVerify = recaptchaResponse => {
        setRecaptchaVerified(true);
    };    


    function contactSubmit(e) {
        e.preventDefault();
        
        if(recaptchaVerified){
            getOautToken()
            .then((token) => {
    
                const payload = JSON.stringify({
                    "name": name,
                    "email": email,
                    "message": message
                  });
    
                const body = {
                    toName: contactInformation.name,
                    toAddress: contactInformation.email,
                    templateID: templateID,
                    payload: payload
                };
    
                axios.post(backendApiUrl+'/v1/notifications/email/sendgrid',
                body,
                {
                headers: {
                    'Authorization': 'Bearer '.concat(token),
                    'due-idee-domain-store': 'dueidee.cl',
                    'Content-Type': 'application/json'
                },
                })
                .then((res) => {return res.data})      
                .then(data => {
                    setContactSended(true);
                })
                .catch(err => console.log(err));             
                
            })
            .catch((e) => {
                console.log(e.message)
            });
        }
    }

    return(
        <Fragment>
            <div className="main-page-wrapper light-bg">
                
                <Menu />

                <div className="inside-hero-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6">
                                <h2 className="page-title font-recoleta"><span>Comunícate</span> con nosotros</h2>
                            </div>
                            <div className="col-xxl-5 col-md-6 ms-auto">
                                <p>Estamos felices de que desees comunicarte con nosotros. Usa cualquiera de nuestros canales que se indican a continuación. Te responderemos lo antes posible.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-section-five">
                
                    <div className="contact-meta mt-20 lg-mt-20">
                        <div className="row gx-0">
                            <div className="col-xl-6 order-xl-last d-flex">
                                <div className="form-wrapper dark-bg w-100">
                                    <div className="form-style-three">
                                        <h3 className="form-title font-recoleta pb-30 lg-pb-20">Envíanos un mensaje.</h3>
                                        <form
                                            id="contact-form"
                                            data-toggle="validator"
                                            onSubmit={contactSubmit.bind(this)}
                                            >
                                            <div className="messages">
                                                {(contactSended) ? <label className="font-recoleta">Tu mensaje fue enviado correctamente</label> : <label></label>}
                                            </div>
                                            <div className="row controls">
                                                <div className="col-12">
                                                    <div className="input-group-meta form-group mb-25">
                                                        <label>Nombre*</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Tu nombre"
                                                            name="name"
                                                            required="required"
                                                            data-error="Name is required."
                                                            onChange={event => setName(event.target.value)}
                                                            />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="input-group-meta form-group mb-25">
                                                        <label>Email*</label>
                                                        <input
                                                            type="email"
                                                            placeholder="Tu email"
                                                            name="email"
                                                            required="required"
                                                            data-error="Valid email is required."
                                                            onChange={event => setEmail(event.target.value)}
                                                            />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="input-group-meta form-group mb-35">
                                                        <textarea
                                                            placeholder="Tu mensaje*"
                                                            name="message"
                                                            required="required"
                                                            data-error="Please,leave us a message."
                                                            onChange={event => setMessage(event.target.value)}
                                                            >
                                                            </textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <Reaptcha className="mb-35" theme="dark" sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onVerify={onVerify} />
                                                    <button className="theme-btn-four ripple-btn w-100" disabled={contactSended}>Enviar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 order-xl-first d-flex">
                                <div className="map-area-two h-100 w-100">
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe className="gmap_iframe" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=es&amp;q=lovleis.com&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-60 mb-60">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="address-block-one text-center mb-40">
                                    <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="assets/images/icon/icon_17.svg" alt=""/></div>
                                    <h5 className="title">Dirección</h5>
                                    <p>{contactInformation.address}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="address-block-one text-center mb-40">
                                    <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="assets/images/icon/icon_18.svg" alt=""/></div>
                                    <h5 className="title">Teléfono</h5>
                                    <p><a href={"tel:"+contactInformation.phoneContact}>{contactInformation.phoneContactFormated}</a></p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="address-block-one text-center mb-40">
                                    <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="assets/images/icon/icon_19.svg" alt=""/></div>
                                    <h5 className="title">Envíanos un mensaje</h5>
                                    <p>Usa nuestro formulario para enviarnos un mensaje</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
           
            </div>

        </Fragment>
    )
}

export default Contact;