/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import Menu from "./Menu";
import Footer from "./Footer";
import { contactInformation } from "../constants/defaultValues";

function Index(){
    return(
        <Fragment>
            <div className="main-page-wrapper light-bg">

                <Menu />

                <div className="hero-banner-two">
                    <div className="container h-100">
                        <div className="main-content h-100">
                            <div className="text-wrapper">
                                <h1 className="hero-heading font-recoleta"><span>Creadores</span> de ideas Online.</h1>
                                <p className="hero-sub-heading text-lg">Estamos construyendo diferentes marcas y plataformas de comercio electrónico y venta presencial.</p>
                            </div>


                            <div className="row gx-md-5 align-items-end h-100">
                                <div className="col-3">
                                    <img src="assets/images/assets/screen_02.png" alt="" />
                                </div>
                                <div className="col-3">
                                    <img src="assets/images/assets/screen_03.png" alt="" className="mb-35" />
                                    <img src="assets/images/assets/screen_04.png" alt="" />
                                </div>
                                <div className="col-3">
                                    <img src="assets/images/assets/screen_05.png" alt="" className="mb-35" />
                                    <img src="assets/images/assets/screen_06.png" alt="" />
                                </div>
                                <div className="col-3">
                                    <img src="assets/images/assets/screen_07.png" alt="" className="mb-35" />
                                    <img src="assets/images/assets/screen_08.png" alt="" />
                                </div>
                            </div>
                            <img src="assets/images/shape/shape_03.svg" alt="" className="shapes shape-one" />
                        </div>
                    </div>
                </div>

                <div className="counter-section-one mt-225 xl-mt-150 md-mt-80">
                    <div className="inner-container">
                        <div className="row justify-content-center">
                            <div className="col-md-3 col-sm-6">
                                <div className="counter-block-one text-center mb-45">
                                    <div className="main-count font-recoleta"><span className="counter">458</span>mil</div>
                                    <p className="theme-mb-0">Visitas anuales a nuestro eCommerce</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="counter-block-one text-center mb-45">
                                    <div className="main-count font-recoleta"><span className="counter">23,3</span>k+ | <span className="counter">22,7</span>k+</div>
                                    <p className="theme-mb-0">Seguidores en RRSS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                


                {/* <div className="vcamp-text-block-one mt-300 xl-mt-200 md-mt-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-6 col-lg-7 ms-auto">
                                <div className="text-wrapper pt-0">
                                    <div className="title-style-two">
                                        <h3 className="title">Somos jóvenes y nos gusta <span>emprender</span> con estilo e ideas innovadoras.</h3>
                                    </div>
                                    <p className="meta-info-text text-lg">Este es el sitio web de la sociedad con la que llevamos adelante nuestros proyectos.</p>
                                    <Link to="/contact" className="theme-btn-one ripple-btn">Contáctanos</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="left-img-meta">
                        <img src="assets/images/team/dueidee-team.jpeg" alt="" />
                        <img src="assets/images/shape/shape_04.svg" alt="" className="shapes shape-one" />
                    </div>
                    <img src="assets/images/shape/shape_05.svg" alt="" className="shapes shape-two" />
                </div> */}

                <div className="contact-section-two mt-200 mb-80 lg-mt-120">
                </div>
                
                <div className="address-section-two">
                    <div className="container">
                        <div className="inner-content">
                            <div className="row g-0">
                                <div className="col-md-6">
                                    <div className="address-block-two d-flex border-right">
                                        <img src="assets/images/icon/icon_34.svg" alt="" className="icon" />
                                        <div className="text-meta">
                                            <h4 className="title">Dirección</h4>
                                            <p>{contactInformation.address}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="address-block-two d-flex">
                                        <img src="assets/images/icon/icon_35.svg" alt="" className="icon" />
                                        <div className="text-meta">
                                            <h4 className="title">Contacto</h4>
                                            <p><a href={"tel:"+contactInformation.phoneContact}>{contactInformation.phoneContactFormated}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
           
            </div>
        </Fragment>
    )
}

export default Index;