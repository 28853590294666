/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { appVersion } from '../constants/defaultValues';

function Footer(props) {
    return (
        <Fragment>
            <div className="vcamp-footer-one dark-bg">
                <div className="clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-11 m-auto">
                                <div className="top-footer md-mt-70">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-2 d-flex justify-content-between flex-column">
                                            <div className="logo"><Link to="/" className="nav-link"><img src="assets/images/logo/logo-due-idee-purp.png" alt="" /></Link></div>
                                            <img src="assets/images/shape/shape_14.svg" alt="" className="shape mt-15 d-none d-md-block" />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <h6 className="footer-title">Sitio</h6>
                                            <ul className="footer-nav-link style-none">
                                                <li><Link to="/" >Inicio</Link></li>
                                                <li><Link to="/contact" >Contáctanos</Link></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <h6 className="footer-title">Marcas</h6>
                                            <ul className="footer-nav-link style-none">
                                                <li><a href="https://www.lovleis.com" target="_blank" rel="noreferrer">Lovleis.com ®</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="bottom-footer">
                                <div className="row">
                                    <div className="col-lg-4 order-lg-0 mb-15">
                                        <ul className="d-flex justify-content-center justify-content-lg-start footer-nav style-none">
                                            <li><Link to="/contact" >Contáctanos</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 order-lg-2 mb-15">
                                        <ul className="d-flex justify-content-center justify-content-lg-end social-icon style-none">
                                            <li><a href="https://www.facebook.com/lovleis.cl" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.instagram.com/lovleis.cl" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://youtube.com/@lovleis-com" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 order-lg-1 mb-15">
                                        <p className="copyright text-center">Copyrights © 2017 - {new Date().getFullYear()}</p>
                                        <p className="copyright text-center">Lovleis ® es una marca registrada por<br/>Due Idee SpA - Rut: 76.794.244-3</p>
                                        <p className="copyright text-center">V{appVersion}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </Fragment>
    );
}

export default Footer;