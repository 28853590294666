export const appVersion = process.env.REACT_APP_VERSION;

export const defaultDomain = 'dueidee.cl';
export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'es', name: 'Español', direction: 'ltr' },
];

export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
export const appOauthToken = process.env.REACT_APP_OAUTH_TOKEN;
export const templateID = 'd-dfc8d578adaf44b4968dc4f1240e61e2'; //Due Idee - Web Site - Contact Form

export const clientID = 'sellers.mktplace.sms.dueidee';

export const contactInformation = {
  name: 'Contacto Due Idee',
  email: 'contacto@dueidee.cl',  
  phoneContact: "+56233048884",
  phoneContactFormated: "(+562) 3304-8884",
  mobilePhoneContact: "56958397089",
  mobilePhoneContactFormated: "(+569) 5839-7089",
  address: "Avenida Apoquindo 6410 Of 212, Las Condes, Santiago, Chile",
};